import React, {useState, useEffect, useContext, useRef} from 'react';
import Styles from './TeleVisionDisplay.module.css';
import {axiosClient} from '../../BaseURL/baseURL';
import AuthContext from '../../context/auth-context';
import { FaPowerOff } from 'react-icons/fa';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import SampleAdImage from '../../assets/dashboard-bg.jpg';
import SampleAdVideo from '../../assets/ad_video.mp4';
import LoaderHeart from '../../reusables/LoaderHeart/LoaderHeart';

const TeleVisionDisplay = (props)=>{
    const {associatedDoctors} = props;
    const authCtx = useContext(AuthContext);
    const [showLoader, setShowLoader ] = useState(true); 
    const [patientList, setPatientList] = useState([]);
    const [isAdsScreenVisible, setIsAdsScreenVisible] = useState(false);
    const videoRef = useRef(null);
    
    const handleLogout = () =>{
        authCtx.onLogout();
    }

    useEffect(()=>{
        window.onload = launchFullScreen(document.documentElement);
    },[]);

    useEffect(()=>{
        const queueScreen = setInterval(()=>{
            getQueData(); 
            setTimeout(showAds, 105000);
        }, 120000);

        return ()=>{
            clearInterval(queueScreen);
        } 
    },[]);

    useEffect(()=>{
        if(isAdsScreenVisible){
            if(videoRef){
                videoRef.current.play();
            }
        }

    },[isAdsScreenVisible]);

    const getQueData = async ()=>{
        try{
            const res = await axiosClient.get(`/queueManager?status=Booked,Confirm,Vaccine&doctor_id=${associatedDoctors[0]}&appointment_date=${getDateInDbFormat(new Date())}&iscancelled=false`);
            console.log(res); 
            if(res.status === 200){
                setPatientList(prev=>{
                    prev = res.data.appointments;
                    return prev;
                });
                setShowLoader(false);
            }
            else{
                window.location.reload();
                setTimeout(()=>{
                    launchFullScreen(document.documentElement);
                }, 1000);
                setShowLoader(true);
            }           
        }
        catch(err){
            console.error(err);
            window.location.reload();
            setTimeout(()=>{
                launchFullScreen(document.documentElement);
            }, 1000);
            setShowLoader(true);
        }
    }

    const showAds = ()=>{
        setIsAdsScreenVisible(true);
        setTimeout(()=>{
            setIsAdsScreenVisible(false);
        }, 14999);
    }

    function getDateInDbFormat(dateString){
        let date = dateString;
        const offset = date.getTimezoneOffset();
        date = new Date(date.getTime() - (offset*60*1000));
        return (date.toISOString().split('T')[0]);
    }

    function launchFullScreen(element) {
        if(element.requestFullScreen) {
          element.requestFullScreen();
        } else if(element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if(element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        }
    }

    

    

    return (    
        <>  
            <Container maxWidth="100%" sx={{paddingBottom: '20px', paddingTop: '20px', height: '98vh', overflow: 'hidden'}}>
                <Box component="div" className={Styles.header} sx={{ width: '100%'}}>
                    <p className={Styles.header_para}>
                        <span>Patients Queue List | অপেক্ষাৰত ৰোগীৰ তালিকা</span>
                        <span className={Styles.logout_btn_wrapper} onClick={handleLogout}>
                            <FaPowerOff />
                        </span>
                    </p>
                </Box>

                <Box component="div" className={Styles.patient_list_wrapper} sx={{ width: '100%', marginTop: '20px'}}>
                    <ul className={patientList.length > 15 ? `${Styles.patient_list}  ${Styles.double}` : `${Styles.patient_list}`}>
                        <li className={Styles.patient_list_header}>
                            <span className={Styles.sl_no_header}>
                                Token Number
                                <span className={`${Styles.arrow} ${Styles.up}`}>&nbsp;</span>
                            </span>
                            <span>Patient Name</span>
                            <span className={Styles.text_center}>Mobile Number</span>
                            <span className={Styles.text_center}>Status</span>
                        </li>
                        {patientList.length > 0 && 
                            patientList.map((i, index)=>{
                                if(index <= 14){
                                    let arrival_status = '';
                                    if(i.status === 'Booked'){
                                        arrival_status = 'Not Arrived';
                                    }
                                    else{
                                        if(i.delay_no === 1){
                                            arrival_status = 'Arrived Late';
                                        }
                                        else{
                                            arrival_status = 'Arrived';
                                        }
                                    }
                                    return(
                                        <li className={i.status === 'Booked' ? `${Styles.Booked}` : `${Styles.Confirm}`} key={index}>
                                            <span className={Styles.sl_no}>
                                                {i.serial_no}
                                            </span>
                                            <span>{`${i.patient_name}`}</span>
                                            <span className={Styles.text_center}>{i.mobile}</span>
                                            <span className={Styles.text_center}>{arrival_status}</span>
                                        </li>
                                    )
                                }                            
                            })
                        }
                        {patientList.length === 0 && (
                            <li className={Styles.no_patients}>
                                {showLoader && (
                                    <>
                                        <LoaderHeart display = {showLoader} />
                                        <span>Please Wait. Loading Patients Queue List</span>
                                    </>
                                )}
                            </li>
                        )}
                    </ul>
                    {patientList.length > 15 && (
                        <ul className={`${Styles.patient_list}  ${Styles.double}`}>
                            <li className={Styles.patient_list_header}>                                
                                <span className={Styles.sl_no_header}>
                                    Token Number
                                    <span className={`${Styles.arrow} ${Styles.up}`}>&nbsp;</span>
                                </span>
                                <span>Patient Name</span>
                                <span className={Styles.text_center}>Mobile Number</span>
                                <span className={Styles.text_center}>Status</span>
                            </li>
                            {patientList.length > 15 && 
                                patientList.map((i, index)=>{
                                    if(index > 14 && index <=29){
                                        let arrival_status = '';
                                        if(i.status === 'Booked'){
                                            arrival_status = 'Not Arrived';
                                        }
                                        else{
                                            if(i.delay_no === 1){
                                                arrival_status = 'Arrived Late';
                                            }
                                            else{
                                                arrival_status = 'Arrived';
                                            }
                                        }
                                        return(
                                            <li className={i.status === 'Booked' ? `${Styles.Booked}` : `${Styles.Confirm}`} key={index}>
                                                {/* <span className={Styles.sl_no}>{index + 1}</span> */}
                                                <span className={Styles.sl_no}>
                                                    {i.serial_no}
                                                </span>
                                                <span>{`${i.patient_name}`}</span>
                                                <span className={Styles.text_center}>{i.mobile}</span>
                                                <span className={Styles.text_center}>{arrival_status}</span>
                                            </li>
                                        )
                                    }                            
                                })
                            }
                            {patientList.length === 0 && (
                                <li className={Styles.no_patients}>
                                    {showLoader && (
                                        <>
                                            <LoaderHeart display = {showLoader} />
                                            <span>Please Wait. Loading Patients Queue List</span>
                                        </>
                                    )}
                                </li>
                            )}
                        </ul>
                    )}
                    
                </Box>
            </Container>

            <div className={isAdsScreenVisible ? `${Styles.ads_screen_wrapper} ${Styles.is_visible}` : `${Styles.ads_screen_wrapper}`}>
                <div className={Styles.ad_wrapper}>
                    {/* <img className={Styles.image_ad} src={SampleAdImage} alt="ads-image" /> */}
                    <video ref={videoRef} className={Styles.video_ad} controls>
                        <source src={SampleAdVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </>   
        
    );
}

export default TeleVisionDisplay;
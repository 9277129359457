import React, {createContext, useState, useEffect} from 'react';
import {axiosClient} from '../BaseURL/baseURL';
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({
    isLogin:false,
    loginData: {},
    errorData: {},
    onLogout: () => {},
    onLogin: (email, password) => {}
});

export const AuthContextProvider = (props)=>{
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);
   
    const [loginData, setLoginData] = useState({
        api_token: "",
        created_at: null,
        email: null,
        id: null,
        mobile: "",
        mobile_verified_at: null,
        name: "",
        updated_at: "",
        user_type: "",
        isError: null
    });

    const [errorData, setErrorData] = useState('');
    
    useEffect(()=>{
        const storedLoggedInUserInfo = localStorage.getItem('isLoggedIn');
        if(storedLoggedInUserInfo === '1'){
            setIsLogin(true);
        }
    }, []);
    
    const logoutHandler = ()=>{
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('token');
        localStorage.removeItem('loggedInUserId');
        localStorage.removeItem('loggedInUserType');
        localStorage.removeItem('loggedInUserName');
        localStorage.removeItem('doctorId');
        localStorage.removeItem('selectedDoctor');
        localStorage.removeItem('associatedDoctors');
        setErrorData('');
        setIsLogin(false);
        navigate('/');
    }

    const  loginHandler = async (userEmail, password)=>{        
        setErrorData('');
        try{
                const res = await axiosClient.post('/login', {
                mobile: userEmail,
                password: password
            }).then(function (res) {
                console.log(res);
                setErrorData('');
                setTimeout(()=>{
                    res = res.data;  
                    console.log(res.data); 
                    localStorage.setItem('isLoggedIn', '1');
                    localStorage.setItem('token', res.data.api_token);
                    localStorage.setItem('loggedInUserId', res.data.id);
                    localStorage.setItem('loggedInUserName', res.data.name);
                    localStorage.setItem('loggedInUserType', res.data.user_type);
                    localStorage.setItem('associatedDoctors', JSON.stringify(res.data.associatedDoctors));
                    setLoginData(prev=>{
                        prev.api_token = res.data.api_token;
                        prev.created_at = res.data.created_at;
                        prev.email = res.data.created_at;
                        prev.id = res.data.id;
                        prev.mobile = res.data.mobile;
                        prev.mobile_verified_at = res.data.mobile_verified_at;
                        prev.name = res.data.name;
                        prev.updated_at = res.data.updated_at;
                        prev.user_type = res.data.user_type;
                        prev.isError = false;
                        return prev;
                    });
                    setIsLogin(true);
                    console.log(res)
                    if(res.data.user_type === 'call_center' || res.data.user_type === 'reception'){
                        navigate('/appointment');
                    }
                    else{
                        navigate('/');
                    }
                    
                },100);
                
            }).catch(function (error) {
                console.log(error);
                setTimeout(()=>{
                    setErrorData(error);
                },100);
            });
        }
        catch(err){
            console.log(err);
        }
    }

    // useEffect(()=>{
    //     console.log(errorData);
    // }, [errorData]);
    
    
    

    return <AuthContext.Provider
        value={{
            isLogin: isLogin,
            onLogin: loginHandler,
            onLogout: logoutHandler,
            loginData: loginData,
            errorData: errorData,
        }}
    >
        {props.children}
    </AuthContext.Provider>
}

export default AuthContext;